import React, { Dispatch, SetStateAction } from 'react';
import cnBind from 'classnames/bind';

import styles from './Tabs.module.scss';

const cx = cnBind.bind(styles);

export const Tabs: React.FC<{
    entities: string[];
    activeIndex: number;
    setActiveSlideIndex?: Dispatch<SetStateAction<number>>;
    startingIndex?: number;
}> = ({ entities, activeIndex, setActiveSlideIndex, startingIndex }) => (
    <ul className={cx('tabs')}>
        {entities.map((_, index) => (
            <li key={`dot-${index}`} className={cx('tab', { 'tab--active': activeIndex === index })}>
                {setActiveSlideIndex && (
                    <button
                        onClick={() => setActiveSlideIndex(index + Number(startingIndex))}
                        type="button"
                        aria-label="Slider dot controls"
                    />
                )}
            </li>
        ))}
    </ul>
);
