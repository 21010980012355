import React, { Dispatch, SetStateAction, useEffect } from 'react';
import cnBind from 'classnames/bind';

import styles from './Modal.module.scss';

const cx = cnBind.bind(styles);

export const Modal: React.FC<{
    isModalOpen: boolean;
    setModalOpen: Dispatch<SetStateAction<boolean>>;
    preloadedImageSrc: string | null;
}> = ({ setModalOpen, isModalOpen, preloadedImageSrc }) => {
    useEffect(() => {
        if (isModalOpen) {
            document.body.classList.add('modal--open');
        } else {
            document.body.classList.remove('modal--open');
        }

        return () => {
            document.body.classList.remove('modal--open');
        };
    }, [isModalOpen]);

    return (
        <div className={cx(['modal-wrapper', { 'modal-wrapper--is-open': isModalOpen }])}>
            <div className={cx('card')}>
                <h2 className={cx('title')}>Not sent</h2>
                <p className={cx('text')}>There was an error while sending the message, please try again</p>
                <div className={cx('image-wrapper')}>
                    {preloadedImageSrc ? (
                        <img
                            className={cx('image')}
                            width="252"
                            height="103"
                            src={preloadedImageSrc}
                            alt="A white paper airplane."
                        />
                    ) : (
                        <p>Internet connection was lost. Please refresh the page.</p>
                    )}
                </div>
                <button className={cx('button')} type="button" onClick={() => setModalOpen(false)}>
                    CLOSE
                </button>
            </div>
        </div>
    );
};
