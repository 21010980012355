import { useState, useEffect } from 'react';
import { BREAKPOINTS } from 'types/App.types';

const isSafariBelow176 = () => {
    const ua = navigator.userAgent;
    const matchSafari = ua.match(/Version\/([0-9._]+).*Safari/);

    if (matchSafari) {
        const version = parseFloat(matchSafari[1]);

        return version < 17.6;
    }

    return false;
};

export const useDetectDevice = () => {
    function getDeviceType() {
        const width = isSafariBelow176() ? document.documentElement.clientWidth : window.innerWidth;

        if (width >= BREAKPOINTS['desktop-lg']) return BREAKPOINTS['desktop-lg'];

        if (width >= BREAKPOINTS.desktop && width < BREAKPOINTS['desktop-lg']) return BREAKPOINTS.desktop;

        if (width >= BREAKPOINTS['desktop-sm'] && width < BREAKPOINTS.desktop) return BREAKPOINTS['desktop-sm'];

        if (width >= BREAKPOINTS.tablet && width < BREAKPOINTS['desktop-sm']) return BREAKPOINTS.tablet;

        return BREAKPOINTS.mobile;
    }

    const [deviceType, setDeviceType] = useState(getDeviceType());

    useEffect(() => {
        const handleResize = () => {
            setDeviceType(getDeviceType());
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return deviceType;
};

export default useDetectDevice;
