import React, { useEffect, useRef, useState } from 'react';
import cnBind from 'classnames/bind';
import { Header } from 'components/Header';
import { Welcome } from 'components/Welcome';
import {
    APPS_DATA,
    APPS_MENU_LINK,
    CONTACT_MENU_LINK,
    HEADER_IDENTIFIER,
    ID_STARTING_INDEX,
    WELCOME_SCREEN_CLASS,
} from 'const';
import { AppCard } from 'components/AppCard';
import { AboutUs } from 'components/AboutUs';
import { MobileMenu } from 'components/MobileMenu';
import { Footer } from 'components/Footer';
import { ContactUs } from 'components/ContactUs';
import { Modal } from 'components/Modal';
import { useModal } from 'hooks/useModal';
import { useDetectDevice, useStopBodyScrolling } from 'hooks';
import { cancelScrollAnimation, scrollPageToElementTopWithOffset } from 'utils';
import { BREAKPOINTS } from 'types/App.types';

import styles from './Main.module.scss';

const cx = cnBind.bind(styles);

const CHANGE_ORIENTATION_TIMEOUT = 700;
const SCROLL_THRESHOLD = 2;
const NO_SMOOTH_SCROLL_CLASS = 'no-smooth-scroll';

export const MainPage: React.FC = () => {
    const footerRef = useRef<HTMLElement | null>(null);
    const startY = useRef<number | null>(null);

    const [modalState, dispatch] = useModal();
    const isMobileDevice = useDetectDevice() === BREAKPOINTS.mobile;
    const [prevScrollTop, setPrevScrollTop] = useState<number>(0);
    const [isHeaderFixed, setHeaderFixed] = useState<boolean>(false);
    const [isMobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);
    const [isMobileMenuVisible, setMobileMenuVisible] = useState<boolean>(true);
    const [isAnimationStarted, setIsAnimationStarted] = useState<boolean>(false);
    const [isFooterIntersected, setFooterIntersected] = useState<boolean>(false);

    useStopBodyScrolling(isMobileMenuOpen);

    useEffect(() => {
        const htmlElement = document.documentElement;

        if (isFooterIntersected) {
            htmlElement.classList.add(NO_SMOOTH_SCROLL_CLASS);
        }

        if (!isAnimationStarted) {
            htmlElement.classList.remove(NO_SMOOTH_SCROLL_CLASS);
        }
    }, [isAnimationStarted, isFooterIntersected]);

    useEffect(() => {
        if (!isMobileDevice) {
            setMobileMenuOpen(false);
        }
    }, [isMobileDevice]);

    useEffect(() => {
        const handleTouchStart = (e: TouchEvent) => {
            if (isAnimationStarted) {
                cancelScrollAnimation();
                setIsAnimationStarted(false);

                return;
            }

            if (!isAnimationStarted && isFooterIntersected) {
                const touch = e.touches[0];
                startY.current = touch.clientY;
            }
        };

        const handleTouchMove = (e: TouchEvent) => {
            if (isAnimationStarted) {
                e.preventDefault();
                e.stopPropagation();

                return;
            }

            if (!isAnimationStarted && isFooterIntersected) {
                const touch = e.touches[0];
                const isSwipedUp = startY.current !== null && touch.clientY - startY.current > 5;

                if (isSwipedUp && isMobileDevice) {
                    e.preventDefault();
                    e.stopPropagation();
                    scrollPageToElementTopWithOffset(window, footerRef.current, () => setIsAnimationStarted(false));
                    setIsAnimationStarted(true);
                    startY.current = null;
                }
            }
        };

        const handleTouchEnd = () => {
            startY.current = null;
        };

        const addEventListeners = () => {
            window.addEventListener('touchstart', handleTouchStart, { passive: false });
            window.addEventListener('touchmove', handleTouchMove, { passive: false });
            window.addEventListener('touchend', handleTouchEnd, { passive: false });
        };

        const removeEventListeners = () => {
            window.removeEventListener('touchstart', handleTouchStart);
            window.removeEventListener('touchmove', handleTouchMove);
            window.removeEventListener('touchend', handleTouchEnd);
        };

        addEventListeners();

        return () => {
            removeEventListeners();
        };
    }, [isFooterIntersected, isAnimationStarted, footerRef, isMobileDevice]);

    // NOTE: HEADER INTERSECTION OBSERVER TO SHOW ON DESKTOP DEVICES
    useEffect(() => {
        const welcomeScreenElement = document.querySelector(`#${WELCOME_SCREEN_CLASS}`);
        const headerElement = document.querySelector(`#${HEADER_IDENTIFIER}`);

        if (!welcomeScreenElement || !headerElement) return;

        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setHeaderFixed(false);
                } else {
                    setHeaderFixed(true);
                }
            },
            {
                threshold: 0,
            },
        );

        observer.observe(welcomeScreenElement);

        return () => {
            observer.unobserve(welcomeScreenElement);
        };
    }, []);

    // NOTE: FOOTER INTERSECTION OBSERVER TO FOLD ON MOBILE DEVICES
    useEffect(() => {
        const footerElement = document.querySelector(`${'#footer'}`);
        const mobileMenuElement = document.querySelector(`${'#mobile-menu'}`);

        if (!footerElement || !mobileMenuElement) return;

        const observer = new IntersectionObserver(
            ([entry]) => {
                setFooterIntersected(entry.isIntersecting);
            },
            {
                root: null,
                rootMargin: `0px 0px 0px 0px`,
                threshold: 0.8,
            },
        );

        observer.observe(footerElement);

        return () => {
            observer.unobserve(footerElement);
        };
    }, []);

    // NOTE: SCROLL OBSERVER TO SHOW MOBILE MENU
    useEffect(() => {
        const checkScrollStatus = () => {
            const scrollTop = window.scrollY;
            const isScrolled = scrollTop !== prevScrollTop;
            const isScrolledDown = isScrolled && scrollTop - SCROLL_THRESHOLD > prevScrollTop;
            const isScrolledUp = isScrolled && scrollTop + SCROLL_THRESHOLD < prevScrollTop;

            if (isScrolledDown && !isMobileMenuOpen) setMobileMenuVisible(false);

            if (isScrolledUp) setMobileMenuVisible(true);

            setPrevScrollTop(scrollTop);
        };

        window.addEventListener('scroll', checkScrollStatus);

        checkScrollStatus();

        return () => {
            window.removeEventListener('scroll', checkScrollStatus);
        };
    }, [prevScrollTop, isMobileMenuOpen]);

    // ? fix iPad change orientation bug
    useEffect(() => {
        const hadleChange = () => {
            setTimeout(() => {
                window.scrollBy({ top: 1 });

                setMobileMenuVisible(true);
            }, CHANGE_ORIENTATION_TIMEOUT);
        };

        window?.screen?.orientation?.addEventListener('change', hadleChange);

        return () => {
            window?.screen?.orientation?.removeEventListener('change', hadleChange);
        };
    }, [isMobileDevice]);

    return (
        <>
            <Header isHeaderFixed={isHeaderFixed} />
            <main className={cx('main')}>
                <Welcome />
                <div className={cx('main-container')}>
                    <div className={cx('apps-wrapper')} id={APPS_MENU_LINK.slice(ID_STARTING_INDEX)}>
                        {APPS_DATA.map((app) => (
                            <AppCard
                                {...app}
                                dispatch={dispatch}
                                isModalOpen={modalState.isModalOpen}
                                key={`app-card-${app.title}`}
                            />
                        ))}
                    </div>
                    <div className={cx('about-us-wrapper')}>
                        <AboutUs />
                    </div>
                    <div className={cx('contact-us-wrapper')} id={CONTACT_MENU_LINK.slice(ID_STARTING_INDEX)}>
                        <ContactUs />
                    </div>
                </div>
            </main>
            <Footer ref={footerRef} />
            <MobileMenu
                isMobileMenuVisible={isMobileMenuVisible}
                isMobileMenuOpen={isMobileMenuOpen}
                setMobileMenuOpen={setMobileMenuOpen}
            />
            <Modal {...modalState} dispatch={dispatch} />
        </>
    );
};
