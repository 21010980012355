import React from 'react';
import cnBind from 'classnames/bind';
import { Logo } from 'assets';
import { HEADER_IDENTIFIER, HEADER_MENU_ITEMS } from 'const';

import styles from './Header.module.scss';

const cx = cnBind.bind(styles);

export const Header: React.FC<{ isHeaderFixed: boolean }> = React.memo(({ isHeaderFixed }) => {
    return (
        <header className={cx(['header', { 'header--is-fixed': isHeaderFixed }])} id={HEADER_IDENTIFIER}>
            <div className={cx('header-content')}>
                <a className={cx('logo-wrapper')} href="/">
                    <Logo />
                </a>
                <nav>
                    <ul className={cx('menu-list')}>
                        {HEADER_MENU_ITEMS.map(({ title, link, subMenuItems }) => (
                            <li
                                className={cx(['menu-item', { 'menu-item--apps': !!subMenuItems }])}
                                key={`header-item-${title}`}
                            >
                                {subMenuItems ? (
                                    <>
                                        <span className={cx('menu-link')} data-text={title}>
                                            {title}
                                        </span>
                                        <div className={cx('submenu')}>
                                            <ul className={cx('submenu-list')}>
                                                {subMenuItems.map(({ title, link, icon }) => (
                                                    <li className={cx('submenu-item')} key={`submenu-item-${title}`}>
                                                        <a className={cx('submenu-link')} href={`/${link}`}>
                                                            <img
                                                                className={cx('submenu-icon')}
                                                                src={icon}
                                                                width="24px"
                                                                height="24px"
                                                                alt={`${title} app.`}
                                                            />
                                                            <span>{title}</span>
                                                        </a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </>
                                ) : (
                                    <a className={cx('menu-link')} href={`/${link}`} data-text={title}>
                                        {title}
                                    </a>
                                )}
                            </li>
                        ))}
                    </ul>
                </nav>
            </div>
        </header>
    );
});
