import { useEffect } from 'react';

export const useStopBodyScrolling = (isModalOpen: boolean, withBodyTouchBlocked = false) => {
    useEffect(() => {
        const preventScroll = (event: TouchEvent) => {
            event.preventDefault();
        };

        if (isModalOpen) {
            document.body.classList.add('modal--open');

            if (withBodyTouchBlocked) document.addEventListener('touchmove', preventScroll, { passive: false });
        } else {
            document.body.classList.remove('modal--open');

            if (withBodyTouchBlocked) document.removeEventListener('touchmove', preventScroll);
        }

        return () => {
            document.body.classList.remove('modal--open');
            document.removeEventListener('touchmove', preventScroll);
        };
    }, [isModalOpen, withBodyTouchBlocked]);
};
