import React, { useEffect, useState } from 'react';
import cnBind from 'classnames/bind';
import { Header } from 'components/Header';
import { PRIVACY_POLICY_CONTENT } from 'locales';
import { Footer } from 'components/Footer';
import { MobileMenu } from 'components/MobileMenu';

import styles from './Privacy.module.scss';

const cx = cnBind.bind(styles);

const policyContent = Object.entries(PRIVACY_POLICY_CONTENT);

export const PrivacyPage: React.FC = React.memo(() => {
    const [isMobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);
    const [isMobileMenuVisible, setMobileMenuVisible] = useState<boolean>(false);
    const [prevScrollTop, setPrevScrollTop] = useState<number>(0);

    useEffect(() => {
        const checkScrollStatus = () => {
            const scrollTop = window.scrollY;
            const isScrolled = scrollTop !== prevScrollTop;
            const isScrolledDown = isScrolled && scrollTop > prevScrollTop;
            const isScrolledUp = isScrolled && scrollTop < prevScrollTop;

            if (isScrolledDown && !isMobileMenuOpen) setMobileMenuVisible(false);

            if (isScrolledUp) setMobileMenuVisible(true);

            setPrevScrollTop(scrollTop);
        };

        window.addEventListener('scroll', checkScrollStatus);

        checkScrollStatus();

        return () => {
            window.removeEventListener('scroll', checkScrollStatus);
        };
    }, [prevScrollTop, isMobileMenuOpen]);

    return (
        <>
            <div className={cx('header-wrapper')}>
                <Header isHeaderFixed />
            </div>
            <div className={cx('page-wrapper')}>
                <main className={cx('main')}>
                    {policyContent.map(([title, paragraphs], index) => (
                        <>
                            {index === 0 ? (
                                <h1 className={cx('title')}>{title}</h1>
                            ) : (
                                <h2 className={cx('subtitle')}>{title}</h2>
                            )}
                            {paragraphs.map((paragraph) => {
                                return (
                                    <div className={cx('paragraph')}>
                                        {paragraph.map((lines) => {
                                            if (typeof lines === 'string') return <p className={cx('line')}>{lines}</p>;

                                            return (
                                                <p className={cx('line')}>
                                                    {lines.map((line) => {
                                                        if (typeof line === 'string') return line;
                                                        const [entry] = Object.entries(line);
                                                        const [text, link] = entry;

                                                        return (
                                                            <a className={cx('link')} href={link}>
                                                                {text}
                                                            </a>
                                                        );
                                                    })}
                                                </p>
                                            );
                                        })}
                                    </div>
                                );
                            })}
                        </>
                    ))}
                </main>
                <div className={cx('footer-wrapper')}>
                    <Footer />
                </div>
                <MobileMenu
                    isMobileMenuVisible={isMobileMenuVisible}
                    isMobileMenuOpen={isMobileMenuOpen}
                    setMobileMenuOpen={setMobileMenuOpen}
                />
            </div>
        </>
    );
});
