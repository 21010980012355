import React from 'react';
import cnBind from 'classnames/bind';
import { Field } from 'react-final-form';
import { FORM_BUTTONS, Topics } from 'const';
import { FORM_FIELDS } from 'locales';

import styles from './AppButtonGroup.module.scss';

const cx = cnBind.bind(styles);

export const AppButtonGroup: React.FC = () => {
    return (
        <fieldset className={cx('app-fieldset')}>
            <legend className={cx('visually-hidden')}>App feedback:</legend>
            {FORM_BUTTONS.map(({ icon, name }) => (
                <Field
                    key={name}
                    name={FORM_FIELDS.topic}
                    type="radio"
                    value={name}
                    initialValue={Topics.COMMON_QUESTIONS_ITEM}
                >
                    {({ input }) => (
                        <label className={cx(['app-button', { 'app-button--active': input.checked }])} htmlFor={name}>
                            <input className={cx('visually-hidden')} {...input} id={name} type="radio" />
                            <img className={cx('app-button-icon')} src={icon} alt={name} />
                            <span className={cx('app-button-text')}>{name}</span>
                        </label>
                    )}
                </Field>
            ))}
        </fieldset>
    );
};
