import React, { memo, SyntheticEvent, useEffect, useRef, useState } from 'react';
import cnBind from 'classnames/bind';
import { Field, useField, useFormState } from 'react-final-form';
import { COUNTRY_INFO, FORM_FIELDS } from 'locales';
import { FormFields } from 'types/App.types';
import { DropdownArrowIcon } from 'assets';

import styles from './DropdownCountry.module.scss';

const cx = cnBind.bind(styles);

const countryFullList = Object.values(COUNTRY_INFO);

export const DropdownCountry: React.FC<{
    name: keyof FormFields;
    initialCountry: { flag: string; name: string } | null;
    reset: boolean;
}> = memo(({ name, initialCountry, reset }) => {
    const [chosenCountry, setChosenCountry] = useState<{ flag: string; name: string } | null>(null);
    const [newChosenCountry, setNewChosenCountry] = useState<{ flag: string; name: string } | null>(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
    const [countriesList, setCountriesList] = useState(countryFullList);
    const { submitFailed } = useFormState({ subscription: { submitFailed: true } });

    const { input, meta } = useField(name);
    const fieldValue = input.value;
    const labelName = FORM_FIELDS[name];
    const isCountryListEmpty = countriesList.length === 0;

    const isInputInvalidValue = meta.active && input.value.length >= 1 && !newChosenCountry;
    const isInputValidCountry = meta.active && input.value.length >= 1 && newChosenCountry;
    const isInputNotEdited = meta.active && input.value.length === 0;

    const countryListRef = useRef<HTMLUListElement | null>(null);
    const selectedCountryRef = useRef<HTMLLIElement | null>(null);
    const countryInputRef = useRef<HTMLInputElement | null>(null);
    const listWrapperRef = useRef<HTMLInputElement | null>(null);

    const normalizedInitialCountry = initialCountry?.name.toLowerCase();
    const normalizedFieldValue = fieldValue?.toLowerCase();
    const normalizedTemporaryCountryValue = chosenCountry?.name.toLowerCase();

    const handleIconClick = (e: SyntheticEvent) => {
        e.preventDefault();

        if (!meta.active && countryInputRef.current) {
            countryInputRef?.current.focus();
            setIsDropdownOpen(true);
        } else {
            countryInputRef.current?.blur();
            setIsDropdownOpen(false);
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const listElement = countryListRef.current;

        if (listElement) {
            listElement.scrollTop = 0;
        }

        input.onChange(e);

        const newChosenCountry = countriesList.find(
            (country) => country.name.toLowerCase() === e.target.value.toLowerCase(),
        );

        if (newChosenCountry) {
            setNewChosenCountry(newChosenCountry);
        }

        const filteredList = countryFullList.filter(({ name }) =>
            name.toLowerCase().includes(e.target.value.toLowerCase()),
        );
        setCountriesList(filteredList);
    };

    const handleFocus = () => {
        input.onChange({ target: { value: '' } });
        input.onFocus();
        setIsDropdownOpen(true);
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        if (
            e.relatedTarget &&
            (e.relatedTarget as HTMLElement).tagName === 'BUTTON' &&
            (e.relatedTarget as HTMLButtonElement).type === 'button'
        ) {
            return;
        }

        if (newChosenCountry) {
            input.onChange({ target: { value: newChosenCountry.name } });
            setChosenCountry(newChosenCountry);
            setIsDropdownOpen(false);
            setCountriesList(countryFullList);
            input.onBlur();
            setNewChosenCountry(null);

            return;
        }

        setNewChosenCountry(null);
        input.onChange({ target: { value: chosenCountry?.name } });
        setCountriesList(countryFullList);
        input.onBlur();
        setIsDropdownOpen(false);
    };

    const handleCountrySelect = (e: React.MouseEvent<HTMLButtonElement>, countryName: string, flag: string) => {
        e.stopPropagation();
        e.preventDefault();

        setChosenCountry({ name: countryName, flag });
        setNewChosenCountry(null);
        setCountriesList(countryFullList);
        setIsDropdownOpen(false);

        setTimeout(() => {
            countryInputRef.current?.blur();
        });
    };

    useEffect(() => {
        if (isDropdownOpen) {
            const listElement = countryListRef.current;
            const selectedElement = selectedCountryRef.current;

            if (listElement && selectedElement) {
                const { offsetTop } = selectedElement;
                const elementHeight = selectedElement.offsetHeight;
                listElement.scrollTop = offsetTop - elementHeight;
            }
        }
    }, [isDropdownOpen]);

    useEffect(() => {
        const listElement = countryListRef.current;

        if (!fieldValue && listElement && !chosenCountry) {
            listElement.scrollTop = 0;
        }
    }, [fieldValue, chosenCountry]);

    useEffect(() => {
        if (initialCountry || reset) {
            setChosenCountry(initialCountry);
        }
    }, [initialCountry, reset]);

    return (
        <Field name={name} component="input" initialValue={chosenCountry?.name}>
            {({ input, meta }) => (
                <div
                    className={cx([
                        'dropdown-wrapper',
                        { 'dropdown-wrapper--has-error': submitFailed && meta.error && meta.touched },
                    ])}
                    ref={listWrapperRef}
                >
                    <div
                        className={cx([
                            'dropdown',
                            { 'dropdown--open': isDropdownOpen },
                            { 'dropdown--has-error': submitFailed && meta.error && meta.touched },
                        ])}
                    >
                        <label
                            className={cx([
                                'field-label',
                                { 'field-label--is-focused': meta.active || Boolean(input.value) },
                                { 'field-label--has-error': submitFailed && meta.touched && meta.error },
                            ])}
                            htmlFor={name}
                        >
                            {labelName}
                        </label>
                        <div
                            className={cx(['field-wrapper', { 'field-wrapper--has-placeholder': !input.value.length }])}
                            data-placeholder={chosenCountry?.name}
                        >
                            <span className={cx(['field-flag', { 'field-flag--inactive': !!chosenCountry }])}>
                                {isInputInvalidValue && null}
                                {isInputValidCountry && newChosenCountry?.flag}
                                {isInputNotEdited && chosenCountry?.flag}
                                {!meta.active && chosenCountry?.flag}
                            </span>
                            <input
                                {...input}
                                className={cx(['field', { 'field--is-focused': meta.active }])}
                                type="text"
                                autoComplete="off"
                                title={fieldValue}
                                onChange={handleChange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                ref={countryInputRef}
                                data-1p-ignore
                            />
                            <button
                                className={cx(['dropdown-wrapper-button'])}
                                onMouseDown={handleIconClick}
                                type="button"
                            >
                                <DropdownArrowIcon
                                    className={cx([
                                        'dropdown-wrapper-icon',
                                        {
                                            'dropdown-wrapper-icon--has-error':
                                                submitFailed && meta.touched && meta.error,
                                        },
                                    ])}
                                />
                            </button>
                        </div>
                        <div
                            className={cx('country-wrapper')}
                            onMouseDown={(e) => {
                                e.preventDefault();
                            }}
                        >
                            <ul className={cx('country-list')} ref={countryListRef}>
                                {!isCountryListEmpty ? (
                                    countriesList.map(({ flag, name }) => {
                                        const normalizedName = name.toLowerCase();

                                        const isSelected = meta.pristine
                                            ? normalizedName === normalizedInitialCountry
                                            : normalizedName === normalizedFieldValue ||
                                              normalizedName === normalizedTemporaryCountryValue;

                                        return (
                                            <li key={name} ref={isSelected ? selectedCountryRef : null}>
                                                <button
                                                    className={cx(['country', { 'country--active': isSelected }])}
                                                    type="button"
                                                    data-text={flag}
                                                    onMouseDown={(e) => handleCountrySelect(e, name, flag)}
                                                >
                                                    {name}
                                                </button>
                                            </li>
                                        );
                                    })
                                ) : (
                                    <li className={cx(['country', 'country--is-empty'])}>No results found</li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            )}
        </Field>
    );
});
