import { Dispatch, SetStateAction } from 'react';

export interface Route {
    (...pathSegments: (string | number)[]): string;
}

export type APIRoutes<T> = {
    [K in keyof T]: Route;
};

export interface NavMenuItem {
    link: string;
    title: string;
    icon?: string;
    subMenuItems?: NavMenuItem[];
}

export type CardStyle = 'dark' | 'light';

export interface AppCardData {
    icon: string;
    title: string;
    subtitle: string;
    shortDescription: string;
    description: string;
    appStoreLink?: string;
    macAppStoreLink?: string;
    sectionLink?: string;
    images: string[];
    style: CardStyle;
    isCarouselVertical?: boolean;
    illustrations: string[];
    type: string;
}

export type ModalAction =
    | {
          type: 'OPEN_MODAL';
          payload: {
              images: string[];
              description: string;
              activeSlideIndex: number;
              setModalActiveSlideIndex: Dispatch<SetStateAction<number>>;
          };
      }
    | { type: 'CLOSE_MODAL' }
    | { type: 'PREVIOUS_SLIDE' }
    | { type: 'NEXT_SLIDE' };

export interface AppCardProps extends AppCardData {
    dispatch: Dispatch<ModalAction>;
    isModalOpen: boolean;
}

export type FormFields = {
    topic: string;
    name: string;
    email: string;
    country: string;
    message: string;
};

export type ParagraphsText = Array<Array<string | Array<string | { [linkText: string]: string }>>>;

export interface PageContent {
    [sectionTitle: string]: ParagraphsText;
}

export enum BREAKPOINTS {
    'mobile-s' = 414,
    'mobile' = 681,
    'mobile-l' = 768,
    'mobile-xl' = 960,
    'tablet' = 961,
    'tablet-lg' = 1100,
    'desktop-sm' = 1101,
    'desktop' = 1280,
    'desktop-lg' = 1440,
}
