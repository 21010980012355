import React from 'react';
import { Parallax } from 'react-scroll-parallax';
import { useDetectDevice, useViewportHeight } from 'hooks';
import { BREAKPOINTS } from 'types/App.types';

import styles from './Illustrations.module.scss';

const MINIMAL_MARGIN_BOTTOM = -100;

const VERTICAL_SPEED = {
    slow: 0,
    average: -50,
    fast: -100,
};

const ZEBRA_BALL_SHIFT = 50;

const getMarginBottomForSpeed = (viewportHeight: number, speed?: 'ultraslow' | 'slow' | 'average' | 'fast') => {
    if (viewportHeight) {
        switch (speed) {
            case 'ultraslow':
                return -(viewportHeight - viewportHeight / 2.1);
            case 'slow':
                return -(viewportHeight - viewportHeight / 3);
            case 'fast':
                return -viewportHeight;
            default:
                return -(viewportHeight - viewportHeight / 6);
        }
    } else {
        return MINIMAL_MARGIN_BOTTOM;
    }
};

export const Illustrations: React.FC<{ type: string; illustrations: string[] }> = React.memo(
    ({ type, illustrations }) => {
        const viewportHeight = useViewportHeight();
        const isMobile = useDetectDevice() === BREAKPOINTS.mobile;

        switch (type) {
            case 'card1':
                return (
                    <>
                        <Parallax
                            className={`${styles.illustration} ${styles['illustration--clock']}`}
                            translateY={isMobile ? [50, 0] : [50, VERTICAL_SPEED.average]}
                            translateX={isMobile ? [50, 0] : [50, 0, [0, 0.8, 0, 1]]}
                            scale={isMobile ? [0.5, 1] : [0.5, 1, [0, 0.8, 0, 1]]}
                            rootMargin={
                                isMobile
                                    ? { top: 0, bottom: getMarginBottomForSpeed(viewportHeight), left: 0, right: 0 }
                                    : { top: -200, bottom: 0, left: 0, right: 0 }
                            }
                        >
                            <img
                                src={illustrations && illustrations[1]}
                                width="268"
                                height="331"
                                alt="Clock illustration."
                            />
                        </Parallax>
                        <Parallax
                            className={`${styles.illustration} ${styles['illustration--orange-ball']}`}
                            translateY={isMobile ? [50, 0] : [50, VERTICAL_SPEED.fast]}
                            translateX={isMobile ? [-50, 0] : [-50, 0, [0, 0.8, 0, 1]]}
                            scale={isMobile ? [0.5, 1] : [0.5, 1, [0, 0.8, 0, 1]]}
                            rootMargin={
                                isMobile
                                    ? {
                                          top: 0,
                                          bottom: getMarginBottomForSpeed(viewportHeight, 'ultraslow'),
                                          left: 0,
                                          right: 0,
                                      }
                                    : { top: -165, bottom: 0, left: 0, right: 0 }
                            }
                        >
                            <img
                                src={illustrations && illustrations[0]}
                                width="218"
                                height="218"
                                alt="Orange ball illustration."
                            />
                        </Parallax>
                    </>
                );
            case 'card2':
                return (
                    <>
                        <Parallax
                            className={`${styles.illustration} ${styles['illustration--rocket']}`}
                            translateY={isMobile ? [50, 0] : [50, VERTICAL_SPEED.average]}
                            translateX={isMobile ? [50, 0] : [-50, 0, [0, 0.8, 0, 1]]}
                            scale={isMobile ? [0.5, 1] : [0.5, 1, [0, 0.8, 0, 1]]}
                            rootMargin={
                                isMobile
                                    ? { top: 0, bottom: getMarginBottomForSpeed(viewportHeight), left: 0, right: 0 }
                                    : { top: -200, bottom: 0, left: 0, right: 0 }
                            }
                        >
                            <img
                                src={illustrations && illustrations[0]}
                                width="268"
                                height="331"
                                alt="Rocket illustration."
                            />
                        </Parallax>
                        <Parallax
                            className={`${styles.illustration} ${styles['illustration--zebra-ball']}`}
                            translateY={isMobile ? [50, 0] : [50, VERTICAL_SPEED.fast]}
                            translateX={isMobile ? [-50, 0] : [50, 0, [0, 0.8, 0, 1]]}
                            scale={isMobile ? [0.5, 1] : [0.5, 1, [0, 0.8, 0, 1]]}
                            rootMargin={
                                isMobile
                                    ? {
                                          top: 0,
                                          bottom:
                                              getMarginBottomForSpeed(viewportHeight, 'ultraslow') - ZEBRA_BALL_SHIFT,
                                          left: 0,
                                          right: 0,
                                      }
                                    : { top: -150, bottom: 0, left: 0, right: 0 }
                            }
                        >
                            <img
                                src={illustrations && illustrations[1]}
                                width="218"
                                height="218"
                                alt="Zebra ball illustration."
                            />
                        </Parallax>
                        <Parallax
                            className={`${styles.illustration} ${styles['illustration--black-ball']}`}
                            translateY={isMobile ? [50, 0] : [50, VERTICAL_SPEED.slow]}
                            translateX={isMobile ? [-50, 0] : [50, 0, [0, 0.8, 0, 1]]}
                            scale={isMobile ? [0.5, 1] : [0.5, 1, [0, 0.8, 0, 1]]}
                            rootMargin={
                                isMobile
                                    ? {
                                          top: 0,
                                          bottom: getMarginBottomForSpeed(viewportHeight, 'slow'),
                                          left: 0,
                                          right: 0,
                                      }
                                    : { top: -50, bottom: 0, left: 0, right: 0 }
                            }
                        >
                            <img
                                src={illustrations && illustrations[2]}
                                width="218"
                                height="218"
                                alt="Black ball illustration."
                            />
                        </Parallax>
                    </>
                );
            case 'card3':
                return (
                    <>
                        <Parallax
                            className={`${styles.illustration} ${styles['illustration--cursor']}`}
                            translateY={isMobile ? [50, 0] : [50, VERTICAL_SPEED.average]}
                            translateX={isMobile ? [50, 0] : [-50, 0, [0, 0.8, 0, 1]]}
                            scale={isMobile ? [0.5, 1] : [0.5, 1, [0, 0.8, 0, 1]]}
                            rootMargin={
                                isMobile
                                    ? { top: 0, bottom: getMarginBottomForSpeed(viewportHeight), left: 0, right: 0 }
                                    : { top: -225, bottom: 0, left: 0, right: 0 }
                            }
                        >
                            <img
                                src={illustrations && illustrations[1]}
                                width="223"
                                height="225"
                                alt="Cursor illustration."
                            />
                        </Parallax>
                        <Parallax
                            className={`${styles.illustration} ${styles['illustration--blue-ball']}`}
                            translateY={isMobile ? [50, 0] : [50, VERTICAL_SPEED.fast]}
                            translateX={isMobile ? [50, 0] : [50, 0, [0, 0.8, 0, 1]]}
                            scale={isMobile ? [0.5, 1] : [0.5, 1, [0, 0.8, 0, 1]]}
                            rootMargin={
                                isMobile
                                    ? {
                                          top: 0,
                                          bottom: getMarginBottomForSpeed(viewportHeight, 'fast'),
                                          left: 0,
                                          right: 0,
                                      }
                                    : { top: -90, bottom: 0, left: 0, right: 0 }
                            }
                        >
                            <img
                                src={illustrations && illustrations[0]}
                                width="118"
                                height="118"
                                alt="Blue ball illustration."
                            />
                        </Parallax>
                    </>
                );
            case 'card4':
                return (
                    <>
                        <Parallax
                            className={`${styles.illustration} ${styles['illustration--book']}`}
                            translateY={isMobile ? [50, 0] : [50, VERTICAL_SPEED.average]}
                            translateX={isMobile ? [50, 0] : [50, 0, [0, 0.8, 0, 1]]}
                            scale={isMobile ? [0.5, 1] : [0.7, 1, [0, 0.8, 0, 1]]}
                            rootMargin={
                                isMobile
                                    ? { top: 0, bottom: getMarginBottomForSpeed(viewportHeight), left: 0, right: 0 }
                                    : { top: -180, bottom: 0, left: 0, right: 0 }
                            }
                        >
                            <img
                                src={illustrations && illustrations[0]}
                                width="292"
                                height="280"
                                alt="Book illustration."
                            />
                        </Parallax>
                        <Parallax
                            className={`${styles.illustration} ${styles['illustration--double-ball']}`}
                            translateY={isMobile ? [50, 0] : [50, VERTICAL_SPEED.average]}
                            translateX={isMobile ? [50, 0] : [-50, 0, [0, 0.8, 0, 1]]}
                            scale={isMobile ? [0.5, 1] : [0.5, 1, [0, 0.8, 0, 1]]}
                            rootMargin={
                                isMobile
                                    ? { top: 0, bottom: getMarginBottomForSpeed(viewportHeight), left: 0, right: 0 }
                                    : { top: -140, bottom: 0, left: 0, right: 0 }
                            }
                        >
                            <img
                                src={illustrations && illustrations[1]}
                                width="175"
                                height="191"
                                alt="Double ball illustration."
                            />
                        </Parallax>
                        <Parallax
                            className={`${styles.illustration} ${styles['illustration--pink-ball']}`}
                            translateY={[50, 0]}
                            translateX={[50, 0]}
                            scale={[0.5, 1]}
                            rootMargin={{ top: 0, bottom: getMarginBottomForSpeed(viewportHeight), left: 0, right: 0 }}
                        >
                            <img
                                src={illustrations && illustrations[2]}
                                width="144"
                                height="144"
                                alt="Double ball illustration."
                            />
                        </Parallax>
                    </>
                );
            default:
                return null;
        }
    },
);
