import { COUNTRY_INFO } from 'locales';
import { FormValues } from 'types/Form.types';

const validateCountryField = (country: string) => {
    if (!country) {
        return 'Country is required';
    }

    const countryList = Object.values(COUNTRY_INFO).map(({ name }) => name);
    const normalizedCountry = country.trim();

    return countryList.includes(normalizedCountry) ? undefined : 'No such country';
};

const validatePlainTextField = (text: string) => {
    if (!text) {
        return 'Field is required';
    }

    return text.trim().length > 0 ? undefined : 'Required';
};

const validateEmailField = (email: string): string | undefined => {
    if (!email) {
        return 'Email is required';
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(email)) {
        return 'Invalid email address';
    }

    return undefined;
};

export const validateFields = (values: FormValues) => {
    const errors: Record<string, string | undefined> = {};

    const nameValidationError = validatePlainTextField(values.name);
    const emailValidationError = validateEmailField(values.email);
    const countryValidationError = validateCountryField(values.country);
    const messageValidationError = validatePlainTextField(values.message);

    if (nameValidationError) {
        errors.name = nameValidationError;
    }

    if (emailValidationError) {
        errors.email = emailValidationError;
    }

    if (countryValidationError) {
        errors.country = countryValidationError;
    }

    if (messageValidationError) {
        errors.message = messageValidationError;
    }

    return errors;
};
