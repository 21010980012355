export const preloadImageWithUrl = (src: string): Promise<string> => {
    return fetch(src)
        .then((response) => {
            if (!response.ok) {
                throw new Error('Failed to load image');
            }

            return response.blob();
        })
        .then((blob) => URL.createObjectURL(blob));
};
