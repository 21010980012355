import React, { forwardRef } from 'react';
import cnBind from 'classnames/bind';
import { Logo } from 'assets';
import { FOOTER_MENU_ITEMS } from 'const';

import styles from './Footer.module.scss';

const cx = cnBind.bind(styles);

export const Footer = forwardRef<HTMLElement>((_, ref) => {
    return (
        <div className={cx(['footer-wrapper'])}>
            <footer className={cx(['footer'])} id="footer" ref={ref}>
                <div className={cx('footer-content')}>
                    <a className={cx('logo-wrapper')} href="/">
                        <Logo />
                    </a>
                    <nav>
                        <ul className={cx('menu-list')}>
                            {FOOTER_MENU_ITEMS.map(({ title, link }) => (
                                <li className={cx('menu-item')} key={`header-item-${title}`}>
                                    <a className={cx('menu-link')} href={`/${link}`} data-text={title}>
                                        {title}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </nav>
                    <p className={cx('copyright')}>© 2020-2024 Villow, LLC. All rights reserved.</p>
                </div>
            </footer>
        </div>
    );
});
