import React from 'react';
import cnBind from 'classnames/bind';

import styles from './SliderControlButton.module.scss';

const cx = cnBind.bind(styles);

export const SliderControlButton: React.FC<{
    handleClick: (event: React.PointerEvent<HTMLButtonElement>) => void;
    type: 'previous' | 'next';
    isDisabled?: boolean;
}> = ({ handleClick, type, isDisabled = false }) => (
    <button
        className={cx('carousel-button', { 'carousel-button--reversed': type === 'next' })}
        type="button"
        onClick={handleClick}
        disabled={isDisabled}
    >
        <span className={cx('visually-hidden')}>Show {type} slide</span>
    </button>
);
