import React, { useState } from 'react';
import cnBind from 'classnames/bind';
import { MobileMenu } from 'components/MobileMenu';
import { Header } from 'components/Header';
import { Footer } from 'components/Footer';
import { Error404Icon } from 'assets';

import styles from './Error.module.scss';

const cx = cnBind.bind(styles);

export const ErrorPage: React.FC = () => {
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

    return (
        <>
            <div className={cx('wrapper')}>
                <Header isHeaderFixed />
                <main className={cx('main')}>
                    <section className={cx('section')}>
                        <Error404Icon className={cx('image')} />
                        <p className={cx('text')}>PAGE NOT FOUND</p>
                        <a className={cx('link')} href="/">
                            GO TO HOME PAGE
                        </a>
                    </section>
                </main>
                <div className={cx('footer-wrapper')}>
                    <Footer />
                </div>
            </div>
            <MobileMenu isMobileMenuVisible isMobileMenuOpen={isMobileMenuOpen} setMobileMenuOpen={setMobileMenuOpen} />
        </>
    );
};
