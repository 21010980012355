import React from 'react';
import cnBind from 'classnames/bind';
import { AppCardProps } from 'types/App.types';
import { DarkAppStoreLogo, DarkMacAppStoreLogo, LightAppStoreLogo, LightMacAppStoreLogo } from 'assets';
import { ID_STARTING_INDEX } from 'const';
import { ParallaxProvider } from 'react-scroll-parallax';
import { Carousel } from 'components/Carousel';

import styles from './AppCard.module.scss';
import { Illustrations } from './Illustrations';

const cx = cnBind.bind(styles);

export const AppCard: React.FC<AppCardProps> = React.memo(
    ({
        icon,
        title,
        subtitle,
        shortDescription,
        description,
        appStoreLink,
        macAppStoreLink,
        sectionLink,
        images,
        style = 'light',
        isCarouselVertical = false,
        type,
        illustrations,
        dispatch,
        isModalOpen,
    }) => (
        <ParallaxProvider>
            <section
                className={cx(['app-card', { 'app-card--dark': style === 'dark' }])}
                id={sectionLink && sectionLink.slice(ID_STARTING_INDEX)}
            >
                <div className={cx('wrapper')}>
                    <div className={cx('info')}>
                        <div className={cx('icon')}>
                            <img src={icon} width="144" height="144" alt={shortDescription} />
                        </div>
                        <div className={cx('app-info')}>
                            <h2 className={cx('title')}>{title}</h2>
                            <p className={cx('slogan')}>{subtitle}</p>
                            <span className={cx('short-description')}>{shortDescription}</span>
                            <p className={cx('description')}>{description}</p>
                            <div className={cx('button-group')}>
                                {macAppStoreLink && (
                                    <a className={cx(['app-link', 'macos-link'])} href={macAppStoreLink}>
                                        {style === 'light' ? <DarkMacAppStoreLogo /> : <LightMacAppStoreLogo />}
                                    </a>
                                )}
                                {appStoreLink && (
                                    <a className={cx(['app-link', 'ios-link'])} href={appStoreLink}>
                                        {style === 'light' ? <DarkAppStoreLogo /> : <LightAppStoreLogo />}
                                    </a>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className={cx('gallery')}>
                        <Carousel
                            images={images}
                            description={shortDescription}
                            style={style}
                            isVertical={isCarouselVertical}
                            isModalOpen={isModalOpen}
                            dispatch={dispatch}
                        />
                    </div>
                </div>
                <Illustrations type={type} illustrations={illustrations} />
            </section>
        </ParallaxProvider>
    ),
);
