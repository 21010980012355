import { Dispatch, SetStateAction, useReducer } from 'react';
import { ModalAction } from 'types/App.types';

type ModalState = {
    images: string[];
    description: string;
    activeSlideIndex: number;
    isModalOpen: boolean;
    setModalActiveSlideIndex: Dispatch<SetStateAction<number>>;
};

const initialState: ModalState = {
    images: [],
    description: '',
    activeSlideIndex: 0,
    isModalOpen: false,
    setModalActiveSlideIndex: () => {},
};

const modalReducer = (state: ModalState, action: ModalAction): ModalState => {
    switch (action.type) {
        case 'OPEN_MODAL':
            return { ...state, ...action.payload, isModalOpen: true };
        case 'CLOSE_MODAL':
            return { ...state, isModalOpen: false };
        case 'NEXT_SLIDE': {
            const nextIndex = (state.activeSlideIndex + 1) % state.images.length;

            return { ...state, activeSlideIndex: nextIndex };
        }
        case 'PREVIOUS_SLIDE': {
            const prevIndex = (state.activeSlideIndex - 1 + state.images.length) % state.images.length;

            return { ...state, activeSlideIndex: prevIndex };
        }
        default:
            return state;
    }
};

export const useModal = () => useReducer(modalReducer, initialState);
