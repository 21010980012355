import React from 'react';
import cnBind from 'classnames/bind';
import { Field, useFormState } from 'react-final-form';
import { FORM_FIELDS } from 'locales';
import { FormFields } from 'types/App.types';

import styles from './FormField.module.scss';

const cx = cnBind.bind(styles);

export const FormField: React.FC<{
    type: 'text' | 'textarea' | 'email';
    name: keyof FormFields;
}> = ({ type, name }) => {
    const isTextArea = type === 'textarea';
    const labelName = FORM_FIELDS[name];
    const { submitFailed } = useFormState({
        subscription: { submitFailed: true },
    });

    return (
        <Field name={name} component={isTextArea ? 'textarea' : 'input'}>
            {({ input, meta }) => (
                <div className={cx('field-wrapper')}>
                    <label
                        className={cx([
                            'field-label',
                            { 'field-label--is-focused': meta.active || Boolean(input.value) },
                            { 'field-label--has-error': submitFailed && meta.touched && meta.error },
                        ])}
                        htmlFor={name}
                    >
                        {labelName}
                    </label>
                    {isTextArea ? (
                        <textarea
                            className={cx([
                                'field',
                                { 'field--is-focused': meta.active || Boolean(input.value) },
                                { 'field--has-error': submitFailed && meta.touched && meta.error },
                                { 'field--textarea': isTextArea },
                            ])}
                            {...input}
                        />
                    ) : (
                        <input
                            className={cx([
                                'field',
                                { 'field--is-focused': meta.active || Boolean(input.value) },
                                { 'field--has-error': submitFailed && meta.touched && meta.error },
                                { 'field--textarea': isTextArea },
                            ])}
                            {...input}
                            type={type}
                        />
                    )}
                </div>
            )}
        </Field>
    );
};
