import { FormValues } from 'types/Form.types';

const API_HOST = process.env.REACT_APP_BACK_HOST;
const API_ROUTE = '/mail';

export const postMessage = async (values: FormValues): Promise<Response> =>
    fetch(`${API_HOST}${API_ROUTE}`, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        },
        body: JSON.stringify(values),
    });
