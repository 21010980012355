import React, { useEffect, useState } from 'react';
import cnBind from 'classnames/bind';
import { CONTACT_MENU_LINK, ID_STARTING_INDEX, PRIVACY_MENU_LINK, Topics } from 'const';
import { Form } from 'react-final-form';
import { FailedFormImage, SusccessFormImage } from 'assets';
import { FormApi } from 'final-form';
import { validateFields, preloadImageWithUrl } from 'utils';
import { useGeoLocation } from 'hooks';
import { FormValues } from 'types/Form.types';
import { postMessage } from 'api/post-message';
import { FORM_FIELDS } from 'locales';

import styles from './ContactUs.module.scss';
import { AppButtonGroup } from './AppButtonGroup';
import { FormField } from './FormField';
import { DropdownCountry } from './DropdownCountry';
import { Modal } from './Modal';

const cx = cnBind.bind(styles);

export const ContactUs: React.FC = React.memo(() => {
    const { country } = useGeoLocation();

    const [isSubmissionSuccessful, setSubmissionSuccessful] = useState<boolean | null>(null);
    const [imageSrc, setImageSrc] = useState<string | null>(null);

    // ? temporary disabled
    // const handlerPatch = useCallback(() => {
    //     return 'Are you sure you want to refresh the page? All data will be lost.';
    // }, []);

    // usePreventUnload(handlerPatch);

    useEffect(() => {
        preloadImageWithUrl(FailedFormImage)
            .then((loadedImageUrl) => {
                setImageSrc(loadedImageUrl);
            })
            .catch(() => {
                setImageSrc(null);
            });
    }, []);

    const handleSubmit = (values: FormValues, form: FormApi<FormValues>) => {
        postMessage(values)
            .then((response) => {
                if (!response.ok) {
                    setSubmissionSuccessful(false);
                    throw new Error(response.statusText);
                }
                form.restart({
                    [FORM_FIELDS.topic]: Topics.COMMON_QUESTIONS_ITEM,
                    country: country?.name,
                });
                setSubmissionSuccessful(true);
            })
            .catch(() => {
                setSubmissionSuccessful(false);
            });
    };

    const handleCloseModal = () => {
        setSubmissionSuccessful(null);
    };

    return (
        <>
            <section className={cx(['card', 'card--dark'])} id={CONTACT_MENU_LINK.slice(ID_STARTING_INDEX)}>
                <h2 className={cx('title', { 'title--is-success': isSubmissionSuccessful })}>
                    {isSubmissionSuccessful ? 'Success' : 'Contact Us'}
                </h2>
                <div className={cx(['form-wrapper', { 'form-wrapper--shorten': isSubmissionSuccessful }])}>
                    <div className={cx('sent-card', { 'sent-card-shown': isSubmissionSuccessful })}>
                        <p className={cx('sent-text')}>
                            Your message has been sent.
                            <br />
                            Thanks for your feedback!
                        </p>
                        <div className={cx('sent-image-wrapper')}>
                            <img className={cx('sent-image')} src={SusccessFormImage} alt="A white paper airplane." />
                        </div>
                        <button className={cx('sent-button')} type="button" onClick={handleCloseModal}>
                            CLOSE
                        </button>
                    </div>
                    <Form<FormValues>
                        onSubmit={handleSubmit}
                        validate={validateFields}
                        render={({ handleSubmit, submitting, hasValidationErrors, submitFailed }) => (
                            <form
                                className={cx('form', { 'form-hidden': isSubmissionSuccessful })}
                                onSubmit={handleSubmit}
                            >
                                <AppButtonGroup />
                                <FormField type="text" name="name" />
                                <div className={cx('fields-wrapper')}>
                                    <FormField type="text" name="email" />
                                    <DropdownCountry
                                        name="country"
                                        initialCountry={country || null}
                                        reset={Boolean(isSubmissionSuccessful)}
                                    />
                                </div>
                                <FormField type="textarea" name="message" />
                                <p className={cx('text')}>
                                    By clicking the “Send“ button, I agree to the processing of the data specified in
                                    the form in accordance with the <a href={PRIVACY_MENU_LINK}>Privacy Policy.</a>
                                </p>
                                <button
                                    className={cx(['submit-button'])}
                                    type="submit"
                                    disabled={(submitFailed && hasValidationErrors) || submitting}
                                >
                                    Send
                                </button>
                            </form>
                        )}
                    />
                </div>
            </section>
            {isSubmissionSuccessful === false && (
                <Modal
                    isModalOpen={isSubmissionSuccessful === false}
                    setModalOpen={handleCloseModal}
                    preloadedImageSrc={imageSrc}
                />
            )}
        </>
    );
});
