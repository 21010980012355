import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { PrivacyPage } from 'pages/Privacy';
import { MainPage } from 'pages/Main';
import { ErrorPage } from 'pages/Error';
import { PRIVACY_MENU_LINK, TERMS_MENU_LINK } from 'const';
import { TermsPage } from 'pages/Terms';

export const App: React.FC = () => (
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path={PRIVACY_MENU_LINK} element={<PrivacyPage />} />
            <Route path={TERMS_MENU_LINK} element={<TermsPage />} />
            <Route path="*" element={<ErrorPage />} />
        </Routes>
    </BrowserRouter>
);
