let isAnimationCancelled = false;

export const scrollPageToElementTopWithOffset = (
    window: Window & typeof globalThis,
    ref: HTMLElement | null,
    onComplete: () => void,
): void => {
    if (!ref) return;

    const footerHeight = ref.offsetHeight;
    const mobileMenuHeight = 80;

    const footerTop = ref.getBoundingClientRect().bottom;
    const scrolledFooterHeight = window.innerHeight - footerTop;

    const targetScroll = window.scrollY - footerHeight - scrolledFooterHeight + mobileMenuHeight;
    const startScroll = window.scrollY;
    const duration = 1000;
    const startTime = performance.now();

    const scrollStep = (currentTime: number): void => {
        if (isAnimationCancelled) {
            isAnimationCancelled = false;

            return;
        }

        const elapsedTime = currentTime - startTime;
        const scrollDistance = Math.min(elapsedTime / duration, 1);

        window.scrollTo(0, startScroll + (targetScroll - startScroll) * scrollDistance);

        if (elapsedTime < duration) {
            requestAnimationFrame(scrollStep);
        } else {
            onComplete();
        }
    };

    requestAnimationFrame(scrollStep);
};

export const cancelScrollAnimation = () => {
    isAnimationCancelled = true;
};
