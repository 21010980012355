import React from 'react';
import cnBind from 'classnames/bind';
import { ABOUT_MENU_LINK, ID_STARTING_INDEX } from 'const';

import styles from './AboutUs.module.scss';

const cx = cnBind.bind(styles);

export const AboutUs: React.FC = React.memo(() => (
    <section className={cx(['card'])} id={ABOUT_MENU_LINK.slice(ID_STARTING_INDEX)}>
        <h2 className={cx('title')}>Villow unites</h2>
        <p className={cx('text')}>
            We are all so different, and that&apos;s wonderful. This allows us to look at our products from different
            angles. What unites us is the desire to make truly high-quality utilities for Mac and iOS, apps that will be
            used. We ourselves use our products in everyday life, we also draw all ideas from life.
        </p>
        <p className={cx(['text', 'text--reversed'])}>
            We are inspired to receive feedback from our users from all over the world and understand that everything we
            do is not in vain. And yes, we also really love constructive criticism and suggestions for improving our
            utilities for Mac and iOS. We actually read and discuss every review on the market, every email. So
            don&apos;t hesitate to write to us, we will definitely answer you.
        </p>
    </section>
));
