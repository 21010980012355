import { IP_LOCATOR_SERVICE } from 'const';
import { COUNTRY_INFO } from 'locales';
import { useState, useEffect, useMemo } from 'react';

export const useGeoLocation = (options = { country: undefined, api: undefined }) => {
    const [countryCode, setCountryCode] = useState<string | undefined | false>(options.country);
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const api = options?.api || IP_LOCATOR_SERVICE;
    const isCountryExistInList = countryCode && Object.keys(COUNTRY_INFO).includes(countryCode);
    const country = useMemo(() => {
        return isCountryExistInList ? COUNTRY_INFO[countryCode] : null;
    }, [isCountryExistInList, countryCode]);

    useEffect(() => {
        let isCancelled = false;

        if (countryCode || countryCode === false) return;
        async function fetchAPI() {
            setIsLoading(true);
            await fetch(api)
                .then((res) => {
                    if (!res.ok) {
                        throw Error(res.statusText);
                    }

                    return res.json();
                })
                .then((res) => {
                    if (res && res.country && !isCancelled) setCountryCode(res.country as string);
                })
                .catch((err) => setError(err))
                .finally(() => setIsLoading(false));
        }
        fetchAPI();

        return () => {
            isCancelled = true;
        };
    }, [api, countryCode]);

    return {
        country,
        error,
        isLoading,
    };
};
