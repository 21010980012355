import React, { useRef, useState } from 'react';
import cnBind from 'classnames/bind';
import { WELCOME_SCREEN_CLASS } from 'const';

import styles from './Welcome.module.scss';

const cx = cnBind.bind(styles);

const SHIFT_INTENSITY = 0.025;

export const Welcome = React.memo(() => {
    const containerRef = useRef<HTMLElement | null>(null);
    const [offset, setOffset] = useState<{ x: number; y: number }>({ x: 0, y: 0 });

    const handleMouseMove = (event: React.MouseEvent) => {
        const sectionElement = containerRef.current;

        if (sectionElement) {
            const rect = sectionElement.getBoundingClientRect();
            const mouseX = event.clientX - rect.left;
            const mouseY = event.clientY - rect.top;

            const containerCenterX = sectionElement.clientWidth / 2;
            const containerCenterY = sectionElement.clientHeight / 2;

            const newOffsetX = -((mouseX - containerCenterX) * SHIFT_INTENSITY);
            const newOffsetY = -((mouseY - containerCenterY) * SHIFT_INTENSITY);

            setOffset({ x: newOffsetX, y: newOffsetY });
        }
    };

    return (
        <section
            className={cx(WELCOME_SCREEN_CLASS)}
            id={WELCOME_SCREEN_CLASS}
            onMouseMove={handleMouseMove}
            ref={containerRef}
        >
            <div className={cx('container')}>
                <div className={cx('content')}>
                    <h1 className={cx('title')}>
                        Welcome to
                        <br />
                        <span>Villow&nbsp;Studio!</span>
                    </h1>
                    <p className={cx('lead')}>
                        Useful Apps to Make Life Easier.
                        <br />
                        Made with care for your everyday needs.
                    </p>
                </div>
                <div
                    className={cx('image-wrapper')}
                    style={{
                        transform: `translate(${offset.x}px, ${offset.y}px)`,
                    }}
                >
                    <picture>
                        <source
                            media="(min-width:961px)"
                            srcSet={`${process.env.PUBLIC_URL}/welcome_bg_desktop.webp`}
                            type="image/webp"
                        />
                        <source
                            media="(min-width:0px)"
                            srcSet={`${process.env.PUBLIC_URL}/welcome_bg_mobile.webp`}
                            type="image/webp"
                        />
                        <img
                            className={cx('image')}
                            src={`${process.env.PUBLIC_URL}/welcome_bg_desktop.webp`}
                            width={1619}
                            height={1619}
                            alt="Abstract colorful background illustration."
                        />
                    </picture>
                </div>
            </div>
        </section>
    );
});
