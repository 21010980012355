import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import cnBind from 'classnames/bind';
import { CrossIcon } from 'assets';
import { ModalAction } from 'types/App.types';
import { useStopBodyScrolling } from 'hooks';

import { Tabs } from '../Tabs';
import { SliderControlButton } from '../SliderControlButton';

import styles from './Modal.module.scss';

const cx = cnBind.bind(styles);

const SWIPE_THRESHOLD = 50;

export const Modal: React.FC<{
    images: string[];
    description: string;
    activeSlideIndex: number;
    setModalActiveSlideIndex: Dispatch<SetStateAction<number>>;
    isModalOpen: boolean;
    dispatch: Dispatch<ModalAction>;
}> = React.memo(({ images, description, activeSlideIndex, setModalActiveSlideIndex, isModalOpen, dispatch }) => {
    const lastSlideIndex = images.length - 1;
    const slideAmount = images.length;
    const [pointerStartX, setPointerStartX] = useState<number>(0);
    const [isDragging, setIsDragging] = useState<boolean>(false);

    useStopBodyScrolling(isModalOpen, true);

    useEffect(() => {
        const handleEscapeKeyPress = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                dispatch({ type: 'CLOSE_MODAL' });
            }
        };

        if (isModalOpen) {
            document.addEventListener('keydown', handleEscapeKeyPress);
        }

        return () => {
            document.removeEventListener('keydown', handleEscapeKeyPress);
        };
    }, [isModalOpen, dispatch]);

    const handleCloseModal = (event: React.MouseEvent | PointerEvent) => {
        event.preventDefault();
        event.stopPropagation();

        dispatch({ type: 'CLOSE_MODAL' });
    };

    const handlePrevious = (event?: React.PointerEvent<HTMLButtonElement>) => {
        if (event) {
            event.stopPropagation();
        }

        setModalActiveSlideIndex((prevIndex) => (prevIndex + lastSlideIndex) % slideAmount);
        dispatch({ type: 'PREVIOUS_SLIDE' });
    };

    const handleNext = (event?: React.PointerEvent<HTMLButtonElement>) => {
        if (event) {
            event.stopPropagation();
        }

        setModalActiveSlideIndex((prevIndex) => (prevIndex + 1) % slideAmount);
        dispatch({ type: 'NEXT_SLIDE' });
    };

    const handlePointerDown = (event: React.PointerEvent) => {
        event.preventDefault();
        event.stopPropagation();

        setIsDragging(true);
        setPointerStartX(event.clientX);
    };

    const handlePointerMove = (event: React.PointerEvent) => {
        event.preventDefault();
        event.stopPropagation();

        if (isDragging) {
            const dragDistance = event.clientX - pointerStartX;
            const isSwipedLeft = dragDistance > 0;

            if (Math.abs(dragDistance) > SWIPE_THRESHOLD) {
                isSwipedLeft ? handlePrevious() : handleNext();
                setPointerStartX(event.clientX);
                setIsDragging(false);
            }
        }
    };

    const handlePointerUp = (event: React.PointerEvent) => {
        event.stopPropagation();
        event.preventDefault();

        setIsDragging(false);
    };

    if (!isModalOpen) {
        return null;
    }

    return (
        <div className={cx('modal-wrapper')} onClick={handleCloseModal}>
            <header className={cx('modal-header')}>
                <div className={cx('header-wrapper')}>
                    <div className={cx('tabs-wrapper')}>
                        <Tabs entities={images} activeIndex={activeSlideIndex} />
                    </div>
                    <button className={cx('icon-wrapper')} type="button" onClick={handleCloseModal}>
                        <CrossIcon className={cx('close-icon')} />
                    </button>
                </div>
            </header>
            <section className={cx('modal-body')}>
                <div className={cx(['control-wrapper', 'control-wrapper--left'])}>
                    <SliderControlButton type="previous" handleClick={handlePrevious} />
                </div>
                <div className={cx(['control-wrapper', 'control-wrapper--right'])}>
                    <SliderControlButton type="next" handleClick={handleNext} />
                </div>
                <div className={cx('image-wrapper')}>
                    <button
                        className={cx('modal-image-button')}
                        type="button"
                        onPointerDown={handlePointerDown}
                        onPointerMove={handlePointerMove}
                        onClick={(e) => e.stopPropagation()}
                        onPointerUp={handlePointerUp}
                        onPointerCancel={handlePointerUp}
                    >
                        <img
                            className={cx('modal-image')}
                            width="375px"
                            height="234px"
                            src={images[activeSlideIndex]}
                            alt={description}
                        />
                    </button>
                </div>
            </section>
        </div>
    );
});
