import React, { Dispatch, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import cnBind from 'classnames/bind';
import { BREAKPOINTS, CardStyle, ModalAction } from 'types/App.types';
import { useDetectDevice } from 'hooks';

import { Tabs } from '../Tabs';
import { SliderControlButton } from '../SliderControlButton';

import styles from './Carousel.module.scss';

const cx = cnBind.bind(styles);

const SWIPE_THRESHOLD = 50;
const ADDITIONAL_ELEMENTS_NUMBER = 2;

export const Carousel: React.FC<{
    images: string[];
    description: string;
    style: CardStyle;
    isVertical?: boolean;
    isModalOpen: boolean;
    dispatch: Dispatch<ModalAction>;
}> = ({ images, description, style, isVertical = false, dispatch, isModalOpen }) => {
    const slideRef = useRef<HTMLLIElement>(null);
    const carouselRef = useRef<HTMLUListElement>(null);

    const device = useDetectDevice();
    const [isDragging, setIsDragging] = useState<boolean>(false);
    const [pointerStartX, setPointerStartX] = useState<number>(0);
    const [timeoutInProgress, setTimeoutInProgress] = React.useState(false);
    const [isTransitionEnabled, setTransitionEnabled] = useState<boolean>(true);
    const [activeSlideIndex, setActiveSlideIndex] = useState<number>(ADDITIONAL_ELEMENTS_NUMBER);
    const [modalActiveSlideIndex, setModalActiveSlideIndex] = useState<number>(0);

    const slideAmount = images.length;
    const isMobile = device === BREAKPOINTS.mobile;
    const leftActualImageIndex = ADDITIONAL_ELEMENTS_NUMBER;
    const leftTransitionSlideIndex = ADDITIONAL_ELEMENTS_NUMBER - 1;
    const rightTransitionSlideIndex = slideAmount + ADDITIONAL_ELEMENTS_NUMBER;
    const rightActualImageIndex = slideAmount + ADDITIONAL_ELEMENTS_NUMBER - 1;
    const actualActiveSlideIndex = (activeSlideIndex - ADDITIONAL_ELEMENTS_NUMBER + slideAmount) % slideAmount;

    // NOTE: Восстанавливает индекс при скролле c ложного слайда на реальный
    useEffect(() => {
        if (activeSlideIndex === leftActualImageIndex || activeSlideIndex === rightActualImageIndex) {
            setTransitionEnabled(true);
        }
    }, [activeSlideIndex, leftActualImageIndex, rightActualImageIndex, rightTransitionSlideIndex, slideAmount]);

    // NOTE: Передает индекс с модального окна в компонент
    useEffect(() => {
        if (isModalOpen) {
            const newActiveSlideIndex = modalActiveSlideIndex + ADDITIONAL_ELEMENTS_NUMBER;
            setActiveSlideIndex(newActiveSlideIndex);
        }
    }, [isModalOpen, modalActiveSlideIndex, slideAmount]);

    const handlePrevious = () => {
        if (activeSlideIndex === leftTransitionSlideIndex) {
            return;
        }

        const isOnEdgeBack = activeSlideIndex <= leftActualImageIndex + 1;

        if (isOnEdgeBack) {
            setTimeoutInProgress(true);
        }

        setActiveSlideIndex((prevIndex) => prevIndex - 1);
    };

    const handleNext = () => {
        if (activeSlideIndex === rightTransitionSlideIndex) {
            return;
        }

        const isOnEdgeForward = activeSlideIndex >= rightActualImageIndex - 1;

        if (isOnEdgeForward) {
            setTimeoutInProgress(true);
        }

        setActiveSlideIndex((prevIndex) => prevIndex + 1);
    };

    const handlePointerDown = (event: React.PointerEvent) => {
        event.preventDefault();
        setIsDragging(true);
        setPointerStartX(event.clientX);
    };

    const handlePointerMove = (event: React.PointerEvent) => {
        if (isDragging) {
            const dragDistance = event.clientX - pointerStartX;

            if (Math.abs(dragDistance) > SWIPE_THRESHOLD) {
                if (dragDistance > 0) {
                    handlePrevious();
                } else {
                    handleNext();
                }
                setPointerStartX(event.clientX);
                setIsDragging(false);
            }
        }
    };

    const handlePointerUp = () => {
        setIsDragging(false);
    };

    const handleSlideClick = useCallback(() => {
        setModalActiveSlideIndex(actualActiveSlideIndex);

        dispatch({
            type: 'OPEN_MODAL',
            payload: {
                images,
                description,
                activeSlideIndex: actualActiveSlideIndex,
                setModalActiveSlideIndex,
            },
        });
    }, [actualActiveSlideIndex, images, description, dispatch]);

    const handleTransitionEnd = () => {
        if (activeSlideIndex === leftTransitionSlideIndex) {
            setTransitionEnabled(false);
            setActiveSlideIndex(rightActualImageIndex);
        } else if (activeSlideIndex === rightTransitionSlideIndex) {
            setTransitionEnabled(false);
            setActiveSlideIndex(ADDITIONAL_ELEMENTS_NUMBER);
        }

        setTimeoutInProgress(false);
    };

    const extraPreviousItems = React.useMemo(() => {
        let output = [];

        output = images.slice(slideAmount - ADDITIONAL_ELEMENTS_NUMBER).map((link, index) => (
            <React.Fragment key={`${index}-block-prev`}>
                <li className={cx(['slide', { 'slide--vertical': isVertical }])}>
                    <button
                        type="button"
                        className={cx([
                            'slide-button',
                            {
                                'slide-button--active': index === activeSlideIndex,
                            },
                        ])}
                        onClick={index === activeSlideIndex ? handleSlideClick : undefined}
                    >
                        <img src={link} alt={description} />
                    </button>
                </li>
            </React.Fragment>
        ));

        return output;
    }, [activeSlideIndex, description, handleSlideClick, images, isVertical, slideAmount]);

    const extraNextItems = React.useMemo(() => {
        let output = [];

        output = images.slice(0, ADDITIONAL_ELEMENTS_NUMBER).map((link, index) => (
            <React.Fragment key={`${index}-block-next`}>
                <li className={cx(['slide', { 'slide--vertical': isVertical }])}>
                    <button
                        type="button"
                        className={cx([
                            'slide-button',
                            {
                                'slide-button--active':
                                    index + slideAmount + ADDITIONAL_ELEMENTS_NUMBER === activeSlideIndex,
                            },
                        ])}
                        onClick={
                            index + slideAmount + ADDITIONAL_ELEMENTS_NUMBER === activeSlideIndex
                                ? handleSlideClick
                                : undefined
                        }
                    >
                        <img src={link} alt={description} />
                    </button>
                </li>
            </React.Fragment>
        ));

        return output;
    }, [activeSlideIndex, description, handleSlideClick, images, isVertical, slideAmount]);

    const transitionStyles = useMemo(() => {
        const transitionProperty = {
            transition: !isTransitionEnabled ? 'none' : undefined,
        };

        const getTransformValue = (slideWidth: number) =>
            `translateX(-${activeSlideIndex * slideWidth - (100 - slideWidth) / 2}%)`;

        switch (device) {
            case BREAKPOINTS['desktop-lg']:
                return {
                    transform: isVertical ? getTransformValue(30) : getTransformValue(68),
                    ...transitionProperty,
                };
            case BREAKPOINTS.desktop:
                return {
                    transform: isVertical ? getTransformValue(35) : getTransformValue(75),
                    ...transitionProperty,
                };
            case BREAKPOINTS['desktop-sm']:
                return {
                    transform: isVertical ? getTransformValue(40) : getTransformValue(95),
                    ...transitionProperty,
                };
            case BREAKPOINTS.tablet:
                return {
                    transform: isVertical ? getTransformValue(50) : getTransformValue(65),
                    ...transitionProperty,
                };
            default:
                return {
                    transform: isVertical ? getTransformValue(39) : getTransformValue(80),
                    ...transitionProperty,
                };
        }
    }, [isTransitionEnabled, device, activeSlideIndex, isVertical]);

    return (
        <div className={cx('carousel-wrapper')}>
            <div
                className={cx([
                    'carousel',
                    { 'carousel--dark': style === 'dark' },
                    { 'carousel--vertical': isVertical },
                ])}
                onPointerDown={handlePointerDown}
                onPointerMove={handlePointerMove}
                onPointerUp={handlePointerUp}
                onPointerCancel={handlePointerUp}
            >
                {isMobile && (
                    <button
                        className={cx([
                            'carousel-side-controls',
                            'carousel-side-controls--left',
                            { 'carousel-side-controls--vertical': isVertical },
                        ])}
                        aria-label="left-control-button"
                        type="button"
                        onClick={handlePrevious}
                        disabled={timeoutInProgress}
                    />
                )}
                <ul
                    className={cx(['carousel-inner', { 'carousel-inner--vertical': isVertical }])}
                    style={transitionStyles}
                    onTransitionEnd={handleTransitionEnd}
                    ref={carouselRef}
                >
                    {extraPreviousItems}
                    {images.map((link, index) => (
                        <React.Fragment key={`${index}-block`}>
                            <li className={cx(['slide', { 'slide--vertical': isVertical }])} ref={slideRef}>
                                <button
                                    type="button"
                                    className={cx([
                                        'slide-button',
                                        {
                                            'slide-button--active':
                                                index + ADDITIONAL_ELEMENTS_NUMBER === activeSlideIndex,
                                        },
                                    ])}
                                    style={{ transition: !isTransitionEnabled || isModalOpen ? 'none' : undefined }}
                                    onClick={
                                        index + ADDITIONAL_ELEMENTS_NUMBER === activeSlideIndex
                                            ? handleSlideClick
                                            : undefined
                                    }
                                >
                                    <img src={link} alt={description} />
                                </button>
                            </li>
                        </React.Fragment>
                    ))}
                    {extraNextItems}
                </ul>
                {isMobile && (
                    <button
                        className={cx([
                            'carousel-side-controls',
                            'carousel-side-controls--right',
                            { 'carousel-side-controls--vertical': isVertical },
                        ])}
                        aria-label="right-control-button"
                        type="button"
                        onClick={handleNext}
                        disabled={timeoutInProgress}
                    />
                )}
                <div className={cx(['control-wrapper', 'control-wrapper--left'])}>
                    <SliderControlButton type="previous" handleClick={handlePrevious} isDisabled={timeoutInProgress} />
                </div>
                <div className={cx(['control-wrapper', 'control-wrapper--right'])}>
                    <SliderControlButton type="next" handleClick={handleNext} isDisabled={timeoutInProgress} />
                </div>
            </div>
            <div className={cx(['dots-wrapper', { 'dots-wrapper--vertical': isVertical }])}>
                <Tabs
                    entities={images}
                    activeIndex={actualActiveSlideIndex}
                    setActiveSlideIndex={setActiveSlideIndex}
                    startingIndex={ADDITIONAL_ELEMENTS_NUMBER}
                />
            </div>
        </div>
    );
};
