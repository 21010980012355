import React from 'react';
import cnBind from 'classnames/bind';
import { CrossIcon, Logo, MenuIcon } from 'assets';
import { APPS_DATA, FOOTER_MENU_ITEMS } from 'const';

import styles from './MobileMenu.module.scss';

const cx = cnBind.bind(styles);

export const MobileMenu: React.FC<{
    isMobileMenuVisible: boolean;
    isMobileMenuOpen: boolean;
    setMobileMenuOpen: (state: boolean) => void;
}> = React.memo(({ isMobileMenuVisible, isMobileMenuOpen, setMobileMenuOpen }) => (
    <>
        <div className={cx(['menu-body-wrapper', { 'menu-body-wrapper--is-open': isMobileMenuOpen }])} id="mobile-menu">
            <div className={cx(['menu-body'])}>
                <figure className={cx('app-wrapper')}>
                    <figcaption className={cx('app-caption')}>OUR APPS</figcaption>
                    <ul className={cx('app-list')}>
                        {APPS_DATA.map(({ icon, sectionLink, shortDescription }, index) => (
                            <li className={cx('app-item')} key={`mobiel-item-${index}`}>
                                <a
                                    className={cx('app-link')}
                                    href={`/${sectionLink}`}
                                    onClick={() => setMobileMenuOpen(false)}
                                >
                                    <img
                                        className={cx('app-icon')}
                                        width="72"
                                        height="72"
                                        src={icon}
                                        alt={shortDescription}
                                    />
                                </a>
                            </li>
                        ))}
                    </ul>
                </figure>
                <ul className={cx('menu-list')}>
                    {FOOTER_MENU_ITEMS.slice(1).map(({ title, link }, index) => (
                        <li className={cx('menu-item')} key={`footer-item-${index}`}>
                            <a className={cx('menu-link')} href={`/${link}`} onClick={() => setMobileMenuOpen(false)}>
                                {title}
                            </a>
                        </li>
                    ))}
                </ul>
                <button
                    className={cx(['close-button', { 'close-button--activated': isMobileMenuOpen }])}
                    type="button"
                    onClick={() => setMobileMenuOpen(false)}
                >
                    <CrossIcon />
                    <span className={cx('visually-hidden')}>Close menu</span>
                </button>
            </div>
        </div>
        <footer
            className={cx([
                'menu-footer',
                { 'menu-footer--is-open': isMobileMenuOpen },
                { 'menu-footer--is-visible': isMobileMenuVisible },
                { 'menu-footer--is-hidden': !isMobileMenuVisible },
            ])}
        >
            <a className={cx('logo-wrapper')} href="/">
                <Logo />
            </a>
            <button
                className={cx(['open-button', { 'open-button--activated': isMobileMenuOpen }])}
                type="button"
                onClick={() => setMobileMenuOpen(true)}
            >
                <MenuIcon />
                <span className={cx('visually-hidden')}>Open menu</span>
            </button>
        </footer>
    </>
));
